import {
  json,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import style from "./tailwind.css?url";
import { combineHeaders, getDomainUrl } from "./utils/misc";
import { getEnv } from "./utils/env.server";
import { honeypot } from "./utils/honeypot.server";
import { getToast } from "./utils/toast.server";
import { eq } from "drizzle-orm";
import { makeTimings, time } from "./utils/timing.server";
import { getUserId, logout } from "./utils/auth/auth.server";
import { GeneralErrorBoundary } from "./components/error-boundary";
import { useNonce } from "./utils/nonce-provider";
import { EpicProgress } from "./components/progress-bar";
import { Toaster } from "./components/ui/sonner";
import { useToast } from "./components/toaster";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { db } from "./utils/db.server";
import { userTable } from "./db";
import { i18next } from "./utils/i18next.server";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";

export const links: LinksFunction = () => {
  return [
    {
      rel: "icon",
      href: "/favicon.png",
      type: "image/png",
    },
    { rel: "stylesheet", href: style },
    {
      rel: "preconnect",
      href: "https://rsms.me/",
    },
    {
      rel: "stylesheet",
      href: "https://rsms.me/inter/inter.css",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans+JP:wght@100..900&display=swap",
    },
  ];
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? "Paletly" : "Error | Paletly" },
    {
      name: "description",
      content:
        "Embrace the power of connection with a platform that redefines how teams interact ",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = await i18next.getLocale(request);
  const timings = makeTimings("root loader");
  const userId = await getUserId(request);

  const userData = userId
    ? await time(
        () =>
          db.query.userTable.findFirst({
            where: eq(userTable.id, userId),
            columns: {
              id: true,
              email: true,
              emailVerified: true,
              headline: true,
              username: true,
              image: true,
              description: true,
              name: true,
              role: true,
              createdAt: true,
              updatedAt: true,
            },
          }),
        { timings, type: "find user", desc: "find user in root" },
      )
    : undefined;

  const url = new URL(request.url);
  const pathname = url.pathname;
  const isLoginRoute = ["/signup", "/signin", "/"].includes(pathname);

  if (!!userData && isLoginRoute) {
    return redirect("/dashboard");
  }

  if (userId && !userData) {
    console.warn("Authentication anomaly: User data not available");
    // The user is authenticated but we can't find them in the database. Maybe they were deleted?
    // Let's log them out.
    await logout({ request });
  }

  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      locale,
      user: userData,
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
      },
      ENV: getEnv(),
      toast,
      honeyProps,
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        toastHeaders,
      ),
    },
  );
}

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
};

function Document({
  children,
  nonce,
  env,
  locale,
}: {
  children: React.ReactNode;
  nonce: string;
  env?: Record<string, string>;
  locale?: string;
}) {
  const { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  if (locale) useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()} className="">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="grid min-h-screen grid-cols-[100%] grid-rows-[1fr_auto]">
        {children}
        <script
          nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

const App = () => {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  useToast(data.toast);

  return (
    <Document nonce={nonce} env={data.ENV} locale={data.locale}>
      <Outlet />

      <Toaster closeButton position="top-center" />
      <EpicProgress />
    </Document>
  );
};

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  );
}

export default AppWithProviders;

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.
  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}
